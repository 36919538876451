<template>
  <main>
    <div class="pb">
      <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
    </div>

    <Form ref="form" :model="form" :rules="validate" label-position="top">
      <Divider orientation="left">基本信息</Divider>
      <Row>
        <Col :span="9" :offset="3">
        <FormItem label="题库分类/科目/章节">
          <Cascader :data="cascaderOptions" v-model="cascaderValue" @on-change="onChangeCascader" v-width="600" />
        </FormItem>

        <FormItem label="题目类型" prop="question_type">
          <Select v-model="form.question_type" @on-change="onChangeQuestionType" class="w50">
            <Option :value="item.id" v-for="item in question_types" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="题目格式" prop="question_name_type">
          <Select v-model="form.question_name_type" class="w50">
            <Option :value="item.id" v-for="item in res_types" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem v-show="form.question_name_type == 0" label="题目内容" prop="question_name">
          <Input type="textarea" placeholder="请输入题目" style="width: 600px" :rows="5" v-model="form.question_name" />
        </FormItem>
        <FormItem v-show="form.question_name_type == 1" label="题目内容" prop="question_name_url">
          <upload-image class="w50-max" name="question-bank" v-model="form.question_name_url" />
        </FormItem>
        <div v-show="form.question_type == 1 || form.question_type == 2">
          <FormItem label="选项格式" prop="option_type">
            <Select v-model="form.option_type" class="w50">
              <Option :value="item.id" v-for="item in res_types" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <div v-show="form.option_type == 0">
            <FormItem label="选项A" prop="option1">
              <Input :maxlength="85" style="width: 400px" placeholder="请输入选项A" show-word-limit v-model="form.option1" />
            </FormItem>
            <FormItem label="选项B" prop="option2">
              <Input :maxlength="85" style="width: 400px" placeholder="请输入选项B" show-word-limit v-model="form.option2" />
            </FormItem>
            <FormItem label="选项C" prop="option3">
              <Input :maxlength="85" style="width: 400px" placeholder="请输入选项C" show-word-limit v-model="form.option3" />
            </FormItem>
            <FormItem label="选项D" prop="option4">
              <Input :maxlength="85" style="width: 400px" placeholder="请输入选项D" show-word-limit v-model="form.option4" />
            </FormItem>
          </div>
          <div v-show="form.option_type == 1">
            <FormItem label="选项A" prop="option1_url">
              <upload-image class="w50-max" name="question-bank" v-model="form.option1_url" />
            </FormItem>
            <FormItem label="选项B" prop="option2_url">
              <upload-image class="w50-max" name="question-bank" v-model="form.option2_url" />
            </FormItem>
            <FormItem label="选项C" prop="option3_url">
              <upload-image class="w50-max" name="question-bank" v-model="form.option3_url" />
            </FormItem>
            <FormItem label="选项D" prop="option4_url">
              <upload-image class="w50-max" name="question-bank" v-model="form.option4_url" />
            </FormItem>
          </div>
          <FormItem label="答案" prop="answer">
            <Select v-if="form.question_type == 1" v-model="form.answer" style="width: 200px">
              <Option :value="item.id" v-for="item in answer_options" :key="item.id">{{ item.name }}</Option>
            </Select>
            <Select v-if="form.question_type == 2" multiple v-model="form.answer_multiple" style="width: 400px">
              <Option :value="item.id" v-for="item in answer_options" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
        </div>
        <div v-show="form.question_type == 3 || form.question_type == 5">
          <FormItem label="答案格式" prop="answer_type">
            <Select v-model="form.answer_type" class="w50">
              <Option :value="item.id" v-for="item in res_types" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem v-show="form.answer_type == 1" label="答案" prop="answer_url">
            <upload-image class="w50-max" name="question-bank" v-model="form.answer_url" />
          </FormItem>
          <FormItem v-show="form.answer_type == 0" label="答案" prop="answer">
            <Input type="textarea" placeholder="请输入答案" style="width: 600px" :rows="5" v-model="form.answer" />
          </FormItem>
        </div>
        <div v-show="form.question_type == 4">
          <FormItem label="答案" prop="answer">
            <RadioGroup v-model="form.answer">
              <Radio :label="0">错误</Radio>
              <Radio :label="1">正确</Radio>
            </RadioGroup>
          </FormItem>
        </div>
        <div v-show="form.question_type == 6">
          <Divider orientation="left">小题管理</Divider>
          <FormItem>
            <Button icon="md-add-circle" shape="circle" @click="addChildQuestion">添加小题</Button>
          </FormItem>
          <div v-for="(item, index) in form.child_question" :key="index">
            <Divider />
            <FormItem label="题目类型">
              <Select v-model="item.question_type" class="w50">
                <Option :value="item1.id" v-for="item1 in child_question_types" :key="item1.id">{{ item1.name }}</Option>
              </Select>
              <Button type="error" icon="md-trash" class="ml" @click="removeChildQuestion(index)"></Button>
            </FormItem>
            <FormItem label="题目格式">
              <Select v-model="item.question_name_type" class="w50">
                <Option :value="item1.id" v-for="item1 in res_types" :key="item1.id">{{ item1.name }}</Option>
              </Select>
            </FormItem>
            <FormItem v-if="item.question_name_type == 0" label="题目内容">
              <Input type="textarea" placeholder="请输入题目" style="width: 600px" :rows="5" v-model="item.question_name" />
            </FormItem>
            <FormItem v-if="item.question_name_type == 1" label="题目内容">
              <upload-image class="w50-max" name="question-bank" v-model="item.question_name_url" />
            </FormItem>
            <div v-if="item.question_type == 1 || item.question_type == 2">
              <FormItem label="选项格式">
                <Select v-model="item.option_type" class="w50">
                  <Option :value="item1.id" v-for="item1 in res_types" :key="item1.id">{{ item1.name }}</Option>
                </Select>
              </FormItem>
              <div v-if="item.option_type == 0">
                <FormItem label="选项A">
                  <Input :maxlength="85" style="width: 400px" placeholder="请输入选项A" show-word-limit
                    v-model="item.option1" />
                </FormItem>
                <FormItem label="选项B">
                  <Input :maxlength="85" style="width: 400px" placeholder="请输入选项B" show-word-limit
                    v-model="item.option2" />
                </FormItem>
                <FormItem label="选项C">
                  <Input :maxlength="85" style="width: 400px" placeholder="请输入选项C" show-word-limit
                    v-model="item.option3" />
                </FormItem>
                <FormItem label="选项D">
                  <Input :maxlength="85" style="width: 400px" placeholder="请输入选项D" show-word-limit
                    v-model="item.option4" />
                </FormItem>
              </div>
              <div v-if="item.option_type == 1">
                <FormItem label="选项A">
                  <upload-image class="w50-max" name="question-bank" v-model="item.option1_url" />
                </FormItem>
                <FormItem label="选项B">
                  <upload-image class="w50-max" name="question-bank" v-model="item.option2_url" />
                </FormItem>
                <FormItem label="选项C">
                  <upload-image class="w50-max" name="question-bank" v-model="item.option3_url" />
                </FormItem>
                <FormItem label="选项D">
                  <upload-image class="w50-max" name="question-bank" v-model="item.option4_url" />
                </FormItem>
              </div>
              <FormItem label="答案">
                <Select v-if="item.question_type == 1" v-model="item.answer" style="width: 200px">
                  <Option :value="item1.id" v-for="item1 in answer_options" :key="item1.id">{{ item1.name }}</Option>
                </Select>
                <Select v-if="item.question_type == 2" multiple v-model="item.answer_multiple" style="width: 400px">
                  <Option :value="item1.id" v-for="item1 in answer_options" :key="item1.id">{{ item1.name }}</Option>
                </Select>
              </FormItem>
            </div>
            <div v-show="item.question_type == 3 || item.question_type == 5">
              <FormItem label="答案格式">
                <Select v-model="item.answer_type" class="w50">
                  <Option :value="item1.id" v-for="item1 in res_types" :key="item1.id">{{ item1.name }}</Option>
                </Select>
              </FormItem>
              <FormItem v-if="item.answer_type == 1" label="答案">
                <upload-image class="w50-max" name="question-bank" v-model="item.answer_url" />
              </FormItem>
              <FormItem v-if="item.answer_type == 0" label="答案">
                <Input type="textarea" placeholder="请输入答案" style="width: 600px" :rows="5" v-model="item.answer" />
              </FormItem>
            </div>
            <div v-show="item.question_type == 4">
              <FormItem label="答案">
                <RadioGroup v-model="item.answer">
                  <Radio :label="0">错误</Radio>
                  <Radio :label="1">正确</Radio>
                </RadioGroup>
              </FormItem>
            </div>
            <FormItem label="解析">
              <editor name="question-bank" v-model="item.analysis" />
            </FormItem>
            <ButtonGroup>
              <Button icon="md-arrow-up" @click="sort(index, 'up')"></Button>
              <Button icon="md-arrow-down" @click="sort(index, 'down')"></Button>
            </ButtonGroup>
          </div>
        </div>
        <div v-show="form.question_type != 6">
          <FormItem label="解析">
            <editor name="question-bank" v-model="form.analysis" />
          </FormItem>
        </div>
        <Divider />

        <FormItem label="是否显示">
          <i-switch v-model="form.status" size="large" :true-value="1" :false-value="0">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
        </Col>
      </Row>

      <Divider />
      <Row>
        <Col :span="9" :offset="3">
        <FormItem>
          <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
          <Button type="success" icon="md-filing" @click="submit" v-else>保存修改</Button>
        </FormItem>
        </Col>
      </Row>
    </Form>
  </main>
</template>

<script>
import UploadImage from "@/components/UploadImage";
import Editor from '@/components/Editor';
export default {
  components: {
    UploadImage,
    Editor
  },
  data() {
    return {
      id: this.$route.params.id,
      form: {
        question_category_id: 0,
        question_subject_id: 0,
        question_section_id: 0,
        question_type: 0,
        question_name: "",
        question_name_type: 0,
        question_name_url: "",
        option_type: 0,
        option1_url: "",
        option2_url: "",
        option3_url: "",
        option4_url: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answer: "",
        answer_multiple: [],
        answer_type: 0,
        answer_url: "",
        status: 1,
        child_question: [],
        analysis: ''
      },
      validate: {},
      question_types: [
        { id: 1, name: "单选题" },
        { id: 2, name: "多选题" },
        { id: 3, name: "填空题" },
        { id: 4, name: "判断题" },
        { id: 5, name: "论述题" },
        { id: 6, name: "阅读理解" }
      ],
      child_question_types: [
        { id: 1, name: "单选题" },
        { id: 2, name: "多选题" },
        { id: 3, name: "填空题" },
        { id: 4, name: "判断题" },
        { id: 5, name: "论述题" }
      ],
      answer_options: [
        { id: 0, name: "A" },
        { id: 1, name: "B" },
        { id: 2, name: "C" },
        { id: 3, name: "D" }
      ],
      res_types: [{ id: 0, name: "文本" }, { id: 1, name: "图片" }],
      cascaderOptions: [],
      cascaderValue: []
    };
  },
  created() {
    this.loadCategorys();
  },
  methods: {
    submit() {
      if (!this.form.question_category_id)
        return this.$Message.error("请选择题库分类");

      if (!this.form.question_subject_id)
        return this.$Message.error("请选择题库科目");

      if (!this.form.question_section_id)
        return this.$Message.error("请选择题库章节");

      if ((this.form.question_type == 1 || this.form.question_type == 2) && !this.form.option_type) {

        if (this.form.option1 == this.form.option2 || this.form.option1 == this.form.option3 || this.form.option1 == this.form.option4 || this.form.option2 == this.form.option3 || this.form.option2 == this.form.option4 || this.form.option3 == this.form.option4)
          return this.$Message.error("有选项内容重复");
      }


      if (
        (this.form.question_type != 1 && this.form.question_type != 2) ||
        this.form.option_type
      ) {
        this.form.option1 = "";
        this.form.option2 = "";
        this.form.option3 = "";
        this.form.option4 = "";
      }
      if (
        (this.form.question_type != 1 && this.form.question_type != 2) ||
        !this.form.option_type
      ) {
        this.form.option1_url = "";
        this.form.option2_url = "";
        this.form.option3_url = "";
        this.form.option4_url = "";
      }
      if (this.form.question_name_type) {
        this.form.question_name = "";
      } else {
        this.form.question_name_url = "";
      }
      if (this.form.answer_type) {
        this.form.answer = "";
      } else {
        this.form.answer_url = "";
      }

      if (this.form.question_type == 2) {
        this.form.answer = this.form.answer_multiple.join();
      }
      if (this.form.question_type == 6) {
        for (let i = 0; i < this.form.child_question.length; i++) {
          if (
            (this.form.child_question[i].question_type != 1 &&
              this.form.child_question[i].question_type != 2) ||
            this.form.option_type
          ) {
            this.form.child_question[i].option1 = "";
            this.form.child_question[i].option2 = "";
            this.form.child_question[i].option3 = "";
            this.form.child_question[i].option4 = "";
          }
          if (
            (this.form.child_question[i].question_type != 1 &&
              this.form.child_question[i].question_type != 2) ||
            !this.form.child_question[i].option_type
          ) {
            this.form.child_question[i].option1_url = "";
            this.form.child_question[i].option2_url = "";
            this.form.child_question[i].option3_url = "";
            this.form.child_question[i].option4_url = "";
          }
          if ((this.form.child_question[i].question_type == 1 || this.form.child_question[i].question_type == 2) && !this.form.child_question[i].option_type) {

            if (this.form.child_question[i].option1 == this.form.child_question[i].option2 || this.form.child_question[i].option1 == this.form.child_question[i].option3 || this.form.child_question[i].option1 == this.form.child_question[i].option4 || this.form.child_question[i].option2 == this.form.child_question[i].option3 || this.form.child_question[i].option2 == this.form.child_question[i].option4 || this.form.child_question[i].option3 == this.form.child_question[i].option4)
              return this.$Message.error("有子题目选项内容重复");
          }
          if (this.form.child_question[i].question_name_type) {
            this.form.child_question[i].question_name = "";
          } else {
            this.form.child_question[i].question_name_url = "";
          }
          if (this.form.child_question[i].answer_type) {
            this.form.child_question[i].answer = "";
          } else {
            this.form.child_question[i].answer_url = "";
          }

          if (this.form.child_question[i].question_type == 2) {
            this.form.child_question[i].answer = this.form.child_question[
              i
            ].answer_multiple.join();
          }
        }
      }

      this.$refs.form.validate(valid => {
        if (valid) {
          const url =
            "/questionBank/" + (this.id ? "update/id/" + this.id : "create");
          this.$http.post(url, this.form).then(res => {
            this.$router.back();
          });
        }
      });
    },
    loadData() {
      this.$http.get("/questionBank/detail/id/" + this.id).then(res => {
        this.form = res;
        this.cascaderValue.push(res.section.subject.category.id);
        this.cascaderValue.push(res.section.subject.id);
        this.cascaderValue.push(res.section.id);
        if (res.question_type == 2) {
          this.form.answer_multiple = res.answer ? res.answer.split(",").map(Number) : [];
        }
        if (res.question_type == 1 || res.question_type == 4) {
          this.form.answer = Number(this.form.answer);
        }
        if (res.question_type == 6) {
          for (let i = 0; i < this.form.child_question.length; i++) {
            if (this.form.child_question[i].question_type == 2) {
              this.form.child_question[i].answer_multiple = this.form.child_question[
                i
              ].answer ? this.form.child_question[
                i
              ].answer
                .split(",")
                .map(Number) : [];
            }
            if (
              this.form.child_question[i].question_type == 1 ||
              this.form.child_question[i].question_type == 4
            ) {
              this.form.child_question[i].answer = Number(
                this.form.child_question[i].answer
              );
            }
          }
        }

      });
    },
    loadCategorys() {
      this.$http.get("/questionCategory/list").then(res => {
        this.id && this.loadData();

        let data = [];
        res.data.forEach(cagegory => {
          let tempCagegory = {
            value: cagegory.id,
            label: cagegory.name,
            children: []
          };
          cagegory.subjects.forEach(subject => {
            let tempSubject = {
              value: subject.id,
              label: subject.name,
              children: []
            };
            subject.sections.forEach(section => {
              tempSubject.children.push({
                value: section.id,
                label: section.name
              });
            });
            tempCagegory.children.push(tempSubject);
          });
          data.push(tempCagegory);
        });
        this.cascaderOptions = data;
      });
    },
    onChangeCascader(e) {
      this.form.question_category_id = e[0];
      if (e.length > 1) {
        this.form.question_subject_id = e[1];
      }
      if (e.length > 2) {
        this.form.question_section_id = e[2];
      }
    },
    onChangeQuestionType(e) {
      if (e == 1) {
        this.form.answer = 0;
      }
      if (e == 2 || e == 3 || e == 5) {
        this.form.answer = "";
      }

      if (e == 4) {
        this.form.answer = 0;
      }
      this.form.answer_url = "";
    },

    addChildQuestion() {
      this.form.child_question.push({
        question_name: "",
        question_name_type: 0,
        question_name_url: "",
        option_type: 0,
        option1_url: "",
        option2_url: "",
        option3_url: "",
        option4_url: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answer: "",
        answer_multiple: [],
        answer_type: 0,
        answer_url: "",
        analysis: ""
      });
    },
    removeChildQuestion(index) {
      this.form.child_question.splice(index, 1);
    },
    sort(index, mode) {
      if (mode == "up" && index == 0) return;

      if (mode == "down" && index == this.form.child_question.length - 1)
        return;

      var item = this.form.child_question[index];
      if (mode == "up") {
        this.form.child_question[index] = this.form.child_question[index - 1];
        this.form.child_question[index - 1] = item;
      } else {
        this.form.child_question[index] = this.form.child_question[index + 1];
        this.form.child_question[index + 1] = item;
      }

      this.$forceUpdate();
    }
  }
};
</script>